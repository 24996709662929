import axios, { AxiosError } from "axios";
import { pushNotificationHandler } from "../common/PushNotification";
import { AdminApi, ClientApi, AuthApi, Configuration } from "../generated/clients";
import { BASE_PATH } from "../generated/clients/base";
import { LoadingIndicatorAdapter } from "../views/LoadingIndicator";

let redirect = true;

export let ShowAdminPage = true;

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json"
  },
  validateStatus: (status) => status < 400
});

axiosInstance.defaults.withCredentials = true

const loadingIndicatorAdapter = new LoadingIndicatorAdapter();
axiosInstance.interceptors.request.use((request) => {
  loadingIndicatorAdapter.show();

  return request;
});
axiosInstance.interceptors.response.use(
  (response) => {
    loadingIndicatorAdapter.hide();

    return response;
  },
  (error: AxiosError) => {
    loadingIndicatorAdapter.hide();

    if (error!.response!.status === 401 && redirect) {
      localStorage.setItem("path", window.location.pathname);
      window.location.href = `${localStorage.getItem("BaseURL")}/as/authorization.oauth2?client_id=${localStorage.getItem(
        "ClientId"
      )}&redirect_uri=https%3A%2F%2F${window.location.host}%2Fsignin-oidc&response_type=code&scope=openid%20offline_access`;
    }

    const data = error.response?.data;

    if (data?.errors) {
      pushNotificationHandler.publish(data.title, "error");
    } else {
      pushNotificationHandler.publish(data, "error");
    }

    return error;
  }
);

export async function initAxios() {
  let apiUrl = await getApiUrl();

  ShowAdminPage = apiUrl.indexOf("selfservice.mbfsa.at") < 0;

  if (apiUrl.endsWith("/")) apiUrl = apiUrl.slice(0, -1);
  initClients(
    new Configuration({
      basePath: apiUrl
    })
  );

  if (localStorage.getItem("BaseURL") === null || localStorage.getItem("ClientId") === null) {
    var a = await AuthApiClient.authConfigGet();
    var b = a.data as any;
    localStorage.setItem("BaseURL", b.item1);
    localStorage.setItem("ClientId", b.item2);
  }
}

async function setToken(code: string) {
  await AuthApiClient.authGet(code, `https://${window.location.host}/signin-oidc`);
  redirect = false;

  setTimeout(() => {
    redirect = true;
  }, 500000);
  setTimeout(() => {
    TimerCallback();
  }, 1770000);
}

async function TimerCallback() {
  await AuthApiClient.authRefreshGet();

    setTimeout(() => {
      TimerCallback();
    }, 1770000);
}

async function getApiUrl(): Promise<string> {
  /* if (process.env.NODE_ENV === "development") {
    const envVarKey = "REACT_APP_SSP_API_URL";
    let serviceUrlEnvVar = process.env[envVarKey];
    if (serviceUrlEnvVar) return serviceUrlEnvVar;
  }

  const portEnvVar = process.env.REACT_APP_PORT;
  if (portEnvVar) return `http://localhost:${process.env.REACT_APP_PORT}`;
*/

  const serviceDiscoveryUrl = new URL(window.location.origin);
  serviceDiscoveryUrl.pathname = "SSP-api-url.txt";
  try {
    const response = await axios.get(serviceDiscoveryUrl.toString());
    const apiLocation = response.data;

    if (apiLocation) return apiLocation;
  } catch (err) {
    console.warn(err);
  }

  return BASE_PATH;
}

let AdminApiClient: AdminApi;
let ClientApiClient: ClientApi;
let AuthApiClient: AuthApi;

function initClients(configuration: Configuration) {
  AdminApiClient = new AdminApi(configuration, undefined, axiosInstance);
  ClientApiClient = new ClientApi(configuration, undefined, axiosInstance);
  AuthApiClient = new AuthApi(configuration, undefined, axiosInstance);
}

export { AdminApiClient, ClientApiClient, setToken };
