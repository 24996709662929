import {useContext} from "react";
import {FilesContext} from "../data/FilesContext";
import { UploadInput } from "../../../component/UploadInput";
import { pushNotificationHandler } from "../../../common/PushNotification";
import { useTranslation } from "react-i18next";


interface Props {
  label: string;
  name: string;
}

const UploadField = ({label, name}: Props) => {
  const { files, setFiles } = useContext(FilesContext);
  const { t } = useTranslation();

  const file = files.find((f) => f.field === name)?.file;
  const handleFileChange = (file?: File) => {
    if (file) {
      var allowedFileTypes = ["pdf", "jpeg", "jpg", "tiff", "webp", "png"]
      if (allowedFileTypes.includes(file.name.split(".").slice(-1)[0])) {
        setFiles([
          ...files.filter((f) => f.field !== name),
          {
            file: file,
            field: name
          }
        ]);
      }
      else {
        pushNotificationHandler.publish(t("upload.fileNotAllowed"), 'error');
        setFiles([]);
      }
    } else {
      setFiles([]);
    }
  };

  return <UploadInput file={file} label={label} onFileChange={handleFileChange} />;
};

export default UploadField;
